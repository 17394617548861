<template>
  <th-page-wrapper>
    <th-datatable
      ref="table"
      :headers="headers"
      do-route
      do-route-filters
      no-meta-check
      resource="webhooks"
      route-base="/settings/webhooks"
      :buttons="computedButtons"
      headers-filterable
      :operations="operations"
      :show-operations="false"
      :resource-query="{ query: resourceQuery }"
      :meta-options="resourceQuery"
      :locale="locale"
      :headers-config="headersConfig"
      @headers-config="handleHeadersConfig"
      @loading-error="handleLoadingError"
    />
  </th-page-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import get from 'just-safe-get'
import typeOf from 'just-typeof'

const headersConfigPath = 'settings.headerFilters.webhooks'

export default {
  name: 'WebhooksAll',
  metaInfo() {
    return {
      title: this.$t('common.resource.webhooks.plural')
    }
  },
  data() {
    return {
      selectedItems: [],
      permissions: {
        create: {
          scopes: ['webhooks:create']
        },
        edit: {
          scopes: ['webhooks:update']
        },
        delete: {
          scopes: ['webhooks:delete']
        }
      },
      headers: [
        {
          field: 'description',
          label: this.$t('pages.webhooks.all.headers.description'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'url',
          label: this.$t('pages.webhooks.all.headers.url'),
          fallback: '-',
          minWidth: 130,
          truncate: true
        },
        {
          field: 'events',
          label: this.$t('pages.webhooks.all.headers.events'),
          fallback: '-',
          minWidth: 140,
          formatter: (row) => {
            return get(row, 'eventList').join(', ')
          },
          truncate: true
        },
        {
          field: 'active',
          label: this.$t('pages.webhooks.all.filters.active.title'),
          fallback: '-',
          formatter: (row) => {
            if (row.active) {
              return this.$t('common.interactions.buttons.yes')
            } else {
              return this.$t('common.interactions.buttons.no')
            }
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      locale: 'Config/getLocale',
      localConfiguration: 'Config/getLocalConfiguration'
    }),
    resourceQuery() {
      return {
        deleted: false
      }
    },
    buttons() {
      return [
        {
          type: 'create',
          scopes: ['webhooks:create']
        }
      ]
    },
    computedButtons() {
      return this.buttons.filter((b) =>
        b.scopes ? this.$checkPermissions({ scopes: b.scopes }) : true
      )
    },
    headersConfig() {
      return get(this.localConfiguration, headersConfigPath, {})
    },
    operations() {
      const permissions =
        typeOf(this.permissions) === 'object' ? this.permissions : {}
      return Object.entries(permissions).reduce(
        (ops, [action = '', { scopes = [] }]) => {
          if (!action || !scopes || !scopes.length) return ops

          const permissionVal = this.$checkPermissions({ scopes })

          if (typeOf(permissionVal === 'boolean')) {
            ops[action] = permissionVal
          }

          return ops
        },
        {}
      )
    }
  },
  mounted() {
    this.$emitter.on('refresh-requested', () => {
      this.$refs.table.refresh()
    })
  },
  beforeUnmount() {
    this.$emitter.off('refresh-requested')
  },
  methods: {
    refresh() {
      this.$refs.table.refresh()
    },
    handleHeadersConfig(config) {
      this.$store.dispatch('Config/setLocalConfigurationValue', {
        path: headersConfigPath,
        value: config || {}
      })
    },
    handleLoadingError() {
      // The error comes from the DB not existing if the user never used the account before
      // After the user create a webhook, a DB will be created and the error should not be there anymore
      this.$refs.table.isDataLoading = false
    }
  }
}
</script>

<style scoped>
.actions {
  display: flex;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: center;
  align-items: center;
}

.actions > * {
  margin-right: 10px;
}

.popover {
  margin-right: 10px;
}

.tools > div {
  display: flex;
  align-items: flex-end;
}
</style>
